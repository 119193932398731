import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "main",
    props: true,
    component: () => import(/* webpackChunkName: 'main' */ "../views/Main.vue"),
  },
  {
    path: "/swap",
    name: "swap",
    props: true,
    component: () => import(/* webpackChunkName: 'swap' */ "../views/Swap.vue"),
  },
  {
    path: "/whyd-staking",
    name: "whyd-staking",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: 'whyd-staking' */ "../views/WrappedHydraStaking.vue"
      ),
  },
  {
    path: "/liquidity-staking",
    name: "liquidity-staking",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: 'liquidity-staking' */ "../views/LiquidityStaking.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
