
























import { Component, InjectReactive, Vue } from "vue-property-decorator";
import { ContractCallParamsForMint } from "@/api";
import MintStep1 from "./MintStep1.vue";
import MintStep2 from "./MintStep2.vue";
import MintStep3 from "./MintStep3.vue";

@Component({
  components: {
    MintStep1,
    MintStep2,
    MintStep3,
  },
})
export default class Mint extends Vue {
  @InjectReactive("whydContractAddress") whydContractAddress!: string | null;
  private activeStep = 1;
  private step0Completed = false;
  private step1Completed = false;
  private step2Completed = false;
  private step3Completed = false;

  private swapTargetEthAddress: string | null = null;
  private mintInfo: ContractCallParamsForMint | null = null;

  private onStep1Complete(swapTargetEthAddress: string): void {
    this.swapTargetEthAddress = swapTargetEthAddress;
    this.step1Completed = true;
    this.activeStep++;
  }

  private onStep2Complete(mintInfo: ContractCallParamsForMint): void {
    this.mintInfo = mintInfo;
    this.step2Completed = true;
    this.activeStep++;
  }

  private onStep3Complete(mintInfo: ContractCallParamsForMint): void {
    this.step3Completed = true;
  }
}
