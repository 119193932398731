













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ContractCallButton extends Vue {
  @Prop() disabled!: boolean;
  @Prop() text!: string;

  private onClick(): void {
    this.$emit("clicked");
  }
}
