

















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Backend } from "@/api";
import { CopyButton } from "@/components/CopyButton";
import { SmartbridgeModal } from "@/components/SmartbridgeModal";

@Component({
  components: {
    CopyButton,
    SmartbridgeModal,
  },
})
export default class MintStep2 extends Vue {
  @Prop({ type: Boolean, required: true }) active!: boolean;
  @Prop({ type: Boolean, required: true }) completed!: boolean;
  @Prop({ type: String, required: false }) swapTargetEthAddress!: string;

  private loading = true;
  private lockHydAddress = "Loading...";
  private smartbridgeMessage = "Loading...";
  private hydraTxId = "";
  private hydraTxInvalidFeedback = "";
  private hydraTxValid: boolean | null = null;
  private hydraTxBeingValidated = false;
  private backendError: string | null = null;
  private smarbridgeModalVisible = false;

  private async updated(): Promise<void> {
    if (!this.swapTargetEthAddress) {
      return;
    }
    const resp = await Backend.getHydTxParamsForMint(this.swapTargetEthAddress);
    if (resp.success) {
      this.lockHydAddress = resp.result!.lockHydAddress;
      this.smartbridgeMessage = resp.result!.smartbridgeMessage;
    } else {
      this.backendError = resp.errorMessage!;
    }

    this.loading = false;
  }

  private async onValidateHydTxClick(): Promise<void> {
    this.hydraTxBeingValidated = true;

    if (!this.hydraTxId) {
      this.hydraTxInvalidFeedback = "Required";
      this.hydraTxValid = false;
      this.hydraTxBeingValidated = false;
      return;
    }

    const resp = await Backend.getMintContractCallParams(
      this.hydraTxId,
      this.swapTargetEthAddress
    );

    if (resp.success) {
      this.hydraTxValid = true;
      this.$emit("completed", resp.result!);
    } else {
      this.hydraTxInvalidFeedback = resp.errorMessage!;
      this.hydraTxValid = false;
    }

    this.hydraTxBeingValidated = false;
  }

  private onSmartbridgeHelpClick(): void {
    this.smarbridgeModalVisible = true;
  }
}
