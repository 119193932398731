


















































import { isValidHydAddress } from "@/utils";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BurnStep1 extends Vue {
  @Prop({ type: Boolean, required: true }) active!: boolean;
  @Prop({ type: Boolean, required: true }) completed!: boolean;

  private targetHydAddress = "";
  private targetHydAddressValid: boolean | null = null;
  private targetHydAddressInvalidFeedback = "";
  private targetHydAddressBeingValidated = false;

  private onHydAddressProvidedBtnClick(): void {
    this.validateHydAddress();
    if (this.targetHydAddressValid) {
      this.$emit("completed", this.targetHydAddress);
    }
  }

  private onTargetHydAddressChange(): void {
    if (this.targetHydAddressValid !== null) {
      this.validateHydAddress();
    }
  }

  private validateHydAddress(): void {
    this.targetHydAddressBeingValidated = true;

    const result = isValidHydAddress(this.targetHydAddress);

    if (result === null) {
      this.targetHydAddressValid = true;
      this.targetHydAddressInvalidFeedback = "";
    } else {
      this.targetHydAddressValid = false;
      this.targetHydAddressInvalidFeedback = result;
    }

    this.targetHydAddressBeingValidated = false;
  }
}
