


























import { Component, Vue } from "vue-property-decorator";
import { Action } from "@/types";

@Component
export default class SwapButtons extends Vue {
  private currentAction = Action.NULL;

  private setAction(action: Action) {
    this.currentAction = action;
    this.$emit("action-changed", action);
  }

  private getMenuVariant(action: Action): string {
    if (this.currentAction == action) {
      return "primary";
    }
    return "outline-primary";
  }

  private Action: typeof Action = Action;
}
