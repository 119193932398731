export enum Action {
  NULL,
  MINT,
  BURN,
}

export enum StakingAction {
  APPROVE = "Approve",
  STAKE = "Stake",
  UNSTAKE = "Unstake",
  CLAIM = "Claim Rewards",
}
