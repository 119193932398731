





































































import { Component, Prop, Vue } from "vue-property-decorator";
import Web3 from "web3";
import { Backend } from "@/api";

@Component
export default class BurnStep3 extends Vue {
  @Prop({ type: Boolean, required: true }) active!: boolean;
  @Prop({ type: Boolean, required: true }) completed!: boolean;
  @Prop({ type: String, required: false }) targetHydAddress!: string;
  @Prop({ type: String, required: false }) metamaskTxId!: string;

  private ethTxId = "";
  private ethTxIdValid: boolean | null = null;
  private ethTxIdInvalidFeedback = "";
  private neverUpdated = true;

  private swapped = false;
  private swapProcessing = false;
  private swapTxId: string | null = null;
  private errorMessage: string | null = null;

  private updated(): void {
    if (this.metamaskTxId && this.neverUpdated) {
      this.ethTxId = this.metamaskTxId;
      this.neverUpdated = false;
    }
  }

  private async onEthTxIdProvidedBtnClick(): Promise<void> {
    this.swapProcessing = true;
    this.validateTransactionId();
    if (this.ethTxIdValid) {
      await this.redeemHyd();
    }
    this.swapProcessing = false;
  }

  private async redeemHyd(): Promise<void> {
    try {
      const result = await Backend.redeemHyd(
        this.ethTxId,
        this.targetHydAddress
      );
      if (result.success === true) {
        this.swapTxId = result.result as string;
        this.swapped = true;
        this.errorMessage = null;
        this.$emit("completed");
      } else {
        this.ethTxIdInvalidFeedback = result.errorMessage as string;
        this.ethTxIdValid = false;
      }
    } catch (e) {
      this.errorMessage = e.message;
    }
  }

  private validateTransactionId(): void {
    if (Web3.utils.isHexStrict(this.ethTxId)) {
      this.ethTxIdValid = true;
      return;
    }
    this.ethTxIdInvalidFeedback =
      "Provide the transaction ID in hex format (e.g. '0xdb0f3809e2c98a0a4b4bcb8cec2b363e6a0c1ee76c51c9f7dccf269bbc789e66').";
    this.ethTxIdValid = false;
  }
}
