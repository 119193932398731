import { Component, InjectReactive, Vue } from "vue-property-decorator";
import Web3 from "web3";

import { getScannerUrl } from "@/config";
import { StakingAction as Action } from "@/types";
import { unitToToken } from "@/utils";

@Component
export default class StakingCore extends Vue {
  @InjectReactive("web3") web3!: Web3 | null;
  @InjectReactive("ethereumAccount") senderAccount!: string | null;

  protected stakingContract: any;
  protected tokenContract: any;

  protected currentAction = Action.APPROVE;
  protected needApproval = true;
  protected awaitingContractCall = false;

  protected amountToken: number | null = null;
  protected maxAmountUnits: string | null = null;
  protected currentStakeUnits: string | null = null;

  protected totalStakeUnits: string | null = null;
  protected metamaskProblem: string | null = null;
  protected scannerUrl = getScannerUrl();

  protected amountTokenDecimal: number | null = null;
  protected amountIsValid = false;
  protected startValidatingAmount = false;

  protected readonly DAYS_IN_YEAR = 365;

  protected get showInput(): boolean {
    return (
      this.currentAction == Action.STAKE || this.currentAction == Action.UNSTAKE
    );
  }

  protected get maxAmountToken(): number {
    const amountUnits =
      this.currentAction == Action.STAKE
        ? this.maxAmountUnits!
        : this.currentStakeUnits!;
    return unitToToken(amountUnits, this.amountTokenDecimal!);
  }

  protected async getStake(): Promise<void> {
    const method = this.stakingContract.methods.balanceOf(this.senderAccount);
    this.currentStakeUnits = await method.call();
  }

  protected async getTotalStake(): Promise<void> {
    const method = this.stakingContract.methods.totalSupply();
    this.totalStakeUnits = await method.call();
  }

  protected async getAllowance(): Promise<void> {
    const method = this.tokenContract.methods.allowance(
      this.senderAccount,
      this.stakingContract.options.address
    );
    const allowance = await method.call();
    this.checkIfApprovalNecessary(allowance);
  }

  private checkIfApprovalNecessary(allowance: string) {
    if (BigInt(allowance) > BigInt(this.maxAmountUnits!)) {
      this.needApproval = false;
      this.onActionSet(Action.STAKE);
      return;
    }
    this.needApproval = true;
    this.onActionSet(Action.APPROVE);
  }

  protected async getAvailableBalance(): Promise<void> {
    const method = this.tokenContract.methods.balanceOf(this.senderAccount);
    this.maxAmountUnits = await method.call();
  }

  protected async approve(): Promise<void> {
    const method = this.tokenContract.methods.approve(
      this.stakingContract.options.address,
      BigInt(
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      )
    );
    await method.send({ from: this.senderAccount });
    this.needApproval = false;
    this.onActionSet(Action.STAKE);
  }

  protected async stake(amountFlake: string): Promise<void> {
    const method = this.stakingContract.methods.stake(amountFlake);
    return await method.send({ from: this.senderAccount });
  }

  protected async unstake(amountFlake: string): Promise<void> {
    const method = this.stakingContract.methods.withdraw(amountFlake);
    return await method.send({ from: this.senderAccount });
  }

  protected async claim(): Promise<void> {
    const method = this.stakingContract.methods.getReward();
    return await method.send({ from: this.senderAccount });
  }

  protected onActionSet(action: Action): void {
    this.metamaskProblem = null;
    if (this.needApproval && action == Action.STAKE) {
      this.currentAction = Action.APPROVE;
      return;
    }
    this.currentAction = action;
  }
}
