import axios from "axios";
import { getConfig } from "./config";

export class Backend {
  private static api = axios.create({
    baseURL: getConfig().backendUrl,
    headers: {
      "Content-Type": "application/json",
    },
    validateStatus: (_) => true,
    timeout: 10000,
  });

  static async getHydTxParamsForMint(
    swapTargetEthAddress: string
  ): Promise<BackendResult<HydTxParamsForMint>> {
    try {
      const resp = await this.api.get(
        `/hyd-whyd/${swapTargetEthAddress}/hyd-tx-params`
      );
      if (resp.status === 200) {
        return { result: resp.data, success: true };
      }

      return { errorMessage: resp.statusText, success: false };
    } catch (e) {
      return { errorMessage: e.message, success: false };
    }
  }

  static async getMintContractCallParams(
    hydTxId: string,
    swapTargetEthAddress: string
  ): Promise<BackendResult<ContractCallParamsForMint>> {
    try {
      const resp = await this.api.get(
        `/hyd-whyd/${swapTargetEthAddress}/mint-params/${hydTxId}`
      );

      if (resp.status === 200) {
        return { result: resp.data, success: true };
      }

      return { errorMessage: resp.data.message, success: false };
    } catch (e) {
      return { errorMessage: e.message, success: false };
    }
  }

  static async getBurnContractCallParams(
    swapTargetHydAddress: string,
    amountFlakes: string
  ): Promise<BackendResult<ContractCallParamsForBurn>> {
    try {
      const resp = await this.api.get(
        `/whyd-hyd/${swapTargetHydAddress}/burn-params/${amountFlakes}`
      );

      if (resp.status === 200) {
        return { result: resp.data, success: true };
      }

      return { errorMessage: resp.data.message, success: false };
    } catch (e) {
      return { errorMessage: e.message, success: false };
    }
  }

  static async redeemHyd(
    ethTxId: string,
    swapTargetHydAddress: string
  ): Promise<BackendResult<string>> {
    try {
      const resp = await this.api.post(`/whyd-hyd/exchange`, {
        ethTxId,
        swapTargetHydAddress,
      });

      if (resp.status === 200) {
        return { result: resp.data, success: true };
      }
      return { errorMessage: resp.data.message, success: false };
    } catch (e) {
      return { errorMessage: e.message, success: false };
    }
  }

  static async getAppInfo(): Promise<BackendResult<AppInfo>> {
    try {
      const resp = await this.api.get(`/info`);

      if (resp.status === 200) {
        return { result: resp.data, success: true };
      }
      return { errorMessage: resp.data.message, success: false };
    } catch (e) {
      return { errorMessage: e.message, success: false };
    }
  }
}

export interface BackendResult<T> {
  success: boolean;
  result?: T;
  errorMessage?: string;
}

export interface AppInfo {
  contractAddress: string;
}

export interface HydTxParamsForMint {
  lockHydAddress: string;
  smartbridgeMessage: string;
}

export interface ContractCallParamsForMint {
  hydraTxHash: string;
  amountHex: string;
  signature: Signature;
  messageHash: string;
}

export interface ContractCallParamsForBurn {
  hydAddressHash: string;
  secret: string;
  amountHex: string;
  signature: Signature;
  messageHash: string;
}

export interface Signature {
  v: string;
  r: string;
  s: string;
}
