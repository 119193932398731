






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class AmountField extends Vue {
  @Prop() visible!: boolean;
  @Prop() maxAmount!: number | null;
  @Prop() startValidating!: boolean;

  private amountIsValid: boolean | null = null;
  private amountInvalidFeedback = "";

  private amount: number | null = null;

  private setMaxAmount(): void {
    this.amount = this.maxAmount;
    this.onAmountChange();
  }

  private onAmountChange(): void {
    if (this.amount) {
      this.$emit("update:amount", this.amount);
      this.$nextTick(() => this.validateAmount());
    }
  }

  @Watch("startValidating")
  private validateAmount(): boolean {
    if (!this.amount) {
      this.amountIsValid = false;
      this.amountInvalidFeedback = "Required";
      return false;
    }

    if (isNaN(parseFloat(this.amount.toString()))) {
      this.amountIsValid = false;
      this.amountInvalidFeedback = "Must be a number";
      return false;
    }

    if (this.amount > this.maxAmount!) {
      this.amountIsValid = false;
      this.amountInvalidFeedback = `You can only enter less than or equal to ${this.maxAmount}`;
      return false;
    }

    this.amountIsValid = true;
    this.amountInvalidFeedback = "";
    this.$emit("update:amountIsValid", this.amountIsValid);
    return true;
  }
}
