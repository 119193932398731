import config from "../config.json";

export interface IConfig {
  backendUrl: string;
  network: string;
  chainId: string;
  wHydStakingLogicAddress: string;
  wHydStakingProxyAddress: string;
  liquidityStakingLogicAddress: string;
  ethLiquidityStakingProxyAddress: string;
  ethUniswapV2PairAddress: string;
  daiAddress: string;
  daiLiquidityStakingProxyAddress: string;
  daiUniswapV2PairAddress: string;
  enabledFeatures: string;
}

export const getConfig = (): IConfig => config;
export const getScannerUrl = (): string => {
  switch (parseInt(config.chainId)) {
    case 3: // Ropsten
      return "https://ropsten.etherscan.io";
    case 4: // Rinkeby
      return "https://rinkeby.etherscan.io";
    default:
      return "https://etherscan.io";
  }
};
