













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CopyButton extends Vue {
  @Prop({ required: true }) itemToCopy!: any;

  private onSuccessfulCopy(): void {
    this.$bvToast.toast("Copied Successfully", {
      variant: "secondary",
      toaster: "b-toaster-bottom-center",
      solid: true,
      autoHideDelay: 2000,
      noCloseButton: true,
      bodyClass: "text-center font-weight-bold",
    });
  }
}
