




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class InfoModal extends Vue {
  @Prop({ required: true }) visible!: boolean;
  @Prop({ required: false, default: "info-circle" }) icon!: string;
  @Prop({ required: false, default: null }) text!: string | null;
}
