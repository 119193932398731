import Big from "big.js";
import bs58check from "bs58check";
import { getConfig } from "./config";

export const LP_DECIMALS = 1e18;
export const WHYD_DECIMALS = 1e8;
export const REWARD_LIQUIDITY_POOL = 25e14;
export const YEARLY_REWARD_WHYD_STAKING = 198340;

export function unitToToken(amount: string, decimal: number): number {
  if (amount) {
    return Big(amount).div(decimal).toNumber();
  }
  return NaN;
}

export function tokenToUnit(amount: number, decimal: number): string {
  if (amount) {
    return Big(amount).mul(decimal).toString();
  }
  return "NaN";
}

export function formatUnitBalances(
  balance: string | null,
  decimal: number
): string {
  if (balance) {
    return formatBalances(unitToToken(balance, decimal));
  }
  return "0";
}

function formatBalances(balance: number | null): string {
  if (!balance) {
    return "0";
  }
  if (balance < 1) {
    return balance.toPrecision(3);
  }
  return balance.toFixed(2);
}

export function isValidHydAddress(address: string | null): null | string {
  if (!address) {
    return "Cannot be empty";
  }

  const isMainnet = getConfig().chainId === "1";
  const hydPubkeyHash = isMainnet ? 100 : 128; // 128 = testnet

  try {
    if (bs58check.decode(address)[0] !== hydPubkeyHash) {
      return "Invalid Hydra address";
    }
  } catch (e) {
    return "Invalid Hydra address";
  }

  if (address.length >= 33 && address.length <= 35) {
    return null;
  } else {
    return "A valid Hydra address is between 33 and 35 characters long";
  }
}
