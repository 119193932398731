














































































import { Component, InjectReactive, Vue } from "vue-property-decorator";
import MetaMaskOnboarding from "@metamask/onboarding";

@Component
export default class MetaMaskConnect extends Vue {
  @InjectReactive("provider") provider: any;

  private onboarding = false;
  private metamask = new MetaMaskOnboarding();
  private metamaskConnectInfoModalVisible = false;

  private get isMetaMaskInstalled(): boolean {
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  private onConnectBtnClick(): void {
    this.metamaskConnectInfoModalVisible = true;
  }

  private cancelMetaMaskConnect(): void {
    this.metamaskConnectInfoModalVisible = false;
  }

  private async openMetaMaskConnect(): Promise<void> {
    this.onboarding = true;
    try {
      await this.provider.request({ method: "eth_requestAccounts" });
      const accounts = await this.provider.request({ method: "eth_accounts" });
      this.$emit("complete", accounts[0]);
    } catch (error) {
      console.error(error);
    } finally {
      this.metamaskConnectInfoModalVisible = false;
      this.onboarding = false;
    }
  }

  private onInstallBtnClick(): void {
    this.onboarding = true;
    this.metamask.startOnboarding();
    this.onboarding = false;
  }
}
