



















import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import Web3 from "web3";

@Component
export default class AccountButton extends Vue {
  @InjectReactive("web3") web3!: Web3;
  @InjectReactive("ethereumAccount") account!: string | null;
  @InjectReactive("whydContractAddress") whydContractAddress!: string | null;
  @Prop({ type: String, required: true }) ethBalance!: string;
  @Prop({ type: String, required: true }) whydBalance!: string;
  private chain = "...";

  get formatAccount(): string {
    if (this.account) {
      return `${this.account.substr(0, 6)}...${this.account.substr(38)}`;
    }
    return "";
  }

  private async created(): Promise<void> {
    const chainId = await this.web3!.eth.getChainId();
    switch (chainId) {
      case 1:
        this.chain = "Mainnet";
        return;
      case 3:
        this.chain = "Ropsten";
        return;
      case 4:
        this.chain = "Rinkeby";
        return;
      default:
        this.chain = "Unknown Network";
        return;
    }
  }

  private onSuccessfulCopy(): void {
    this.$bvToast.toast("Copied Successfully", {
      variant: "secondary",
      toaster: "b-toaster-bottom-center",
      solid: true,
      autoHideDelay: 2000,
      noCloseButton: true,
      bodyClass: "text-center font-weight-bold",
    });
  }
}
