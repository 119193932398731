


























import { Component, Prop, Vue } from "vue-property-decorator";
import { StakingAction as Action } from "@/types";

@Component
export default class ActionButtons extends Vue {
  @Prop({ required: true }) currentAction!: Action;

  private setAction(action: Action): void {
    this.$emit("action-set", action);
  }

  private buttonColor(action: Action): string {
    if (action == this.currentAction) {
      return "primary";
    }
    if (this.currentAction == Action.APPROVE && action == Action.STAKE) {
      return "primary";
    }
    return "outline-primary";
  }

  private Action: typeof Action = Action;
}
