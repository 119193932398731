















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class StakingHeader extends Vue {
  @Prop({ required: true, type: String }) title!: string;

  private onClick(): void {
    this.$emit("refresh");
  }

  private get titleWidth(): number {
    if (this.title.length > 16) {
      return 8;
    }
    return 4;
  }
}
