



















































import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import Web3 from "web3";

@Component
export default class MintStep1 extends Vue {
  @Prop({ type: Boolean, required: true }) active!: boolean;
  @Prop({ type: Boolean, required: true }) completed!: boolean;
  @InjectReactive("web3") web3!: Web3;
  @InjectReactive("ethereumAccount") ethereumAccount!: string | null;

  private swapTargetEthAddress = "";
  private swapTargetEthAddressValid: boolean | null = null;
  private swapTargetEthAddressInvalidFeedback = "";
  private swapTargetEthAddressBeingValidated = false;

  private created(): void {
    if (this.ethereumAccount) {
      this.swapTargetEthAddress = this.ethereumAccount;
    }
  }
  private async onEthAddressProvidedBtnClick(): Promise<void> {
    const step1Valid = await this.validateSwapEthAddress();
    if (step1Valid) {
      this.$emit("completed", this.swapTargetEthAddress);
    }
  }

  private async onswapTargetEthAddressChange(): Promise<void> {
    if (this.swapTargetEthAddressValid !== null) {
      await this.validateSwapEthAddress();
    }
  }

  private async validateSwapEthAddress(): Promise<boolean> {
    this.swapTargetEthAddressBeingValidated = true;

    if (!Web3.utils.isAddress(this.swapTargetEthAddress)) {
      this.swapTargetEthAddressInvalidFeedback = "Invalid Ethereum address.";
      this.swapTargetEthAddressValid = false;
      this.swapTargetEthAddressBeingValidated = false;
      return false;
    }

    if (this.web3) {
      const balance = BigInt(
        await this.web3!.eth.getBalance(this.swapTargetEthAddress)
      );
      if (balance < BigInt(1e18 / 100)) {
        // 0.01 ETH
        const ethBalance = BigInt(balance / BigInt(1e18));
        this.swapTargetEthAddressInvalidFeedback = `Low balance (${ethBalance.toString()} ETH). You need at least 0.01 ETH on your address to claim your WHYD.`;
        this.swapTargetEthAddressValid = false;
        this.swapTargetEthAddressBeingValidated = false;
        return false;
      }
    }

    this.swapTargetEthAddressValid = true;
    this.swapTargetEthAddressBeingValidated = false;
    return true;
  }
}
