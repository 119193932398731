












































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SmartbridgeModal extends Vue {
  @Prop({ required: true }) visible!: boolean;
  private active = 0;

  private getVariant(menu: number): string {
    return menu === this.active ? "secondary" : "outline-secondary";
  }

  private setActive(menu: number): void {
    this.active = menu;
  }
}
