

















































































import { Component, InjectReactive, Prop, Watch } from "vue-property-decorator";
import Big from "big.js";
import { AbiItem } from "web3-utils";

import UniswapV2PairAbi from "@/contracts/UniswapV2Pair.json";
import LiquidityStakingAbi from "@/contracts/LiquidityStaking.json";
import WrappedHydraAbi from "@/contracts/WrappedHydra.json";
import { ActionButtons } from "@/components/ActionButtons";
import { AmountField } from "@/components/AmountField";
import { ContractCallButton } from "@/components/ContractCallButton";
import { InfoModal } from "@/components/InfoModal";
import { InfoTable } from "@/components/InfoTable";
import { StakingCore } from "@/components/StakingCore";
import {
  formatUnitBalances,
  tokenToUnit,
  LP_DECIMALS,
  REWARD_LIQUIDITY_POOL,
  WHYD_DECIMALS,
} from "@/utils";
import { StakingAction as Action } from "@/types";
import { getConfig } from "@/config";

@Component({
  components: {
    ActionButtons,
    AmountField,
    ContractCallButton,
    InfoModal,
    InfoTable,
  },
})
export default class LiquidityStakingInterface extends StakingCore {
  @InjectReactive("whydContractAddress") whydContractAddress!: string | null;

  @Prop({ type: String, required: true }) pairAddress!: string;
  @Prop({ type: String, required: true }) stakingContractProxyAddress!: string;
  @Prop({ type: String, required: true }) tokenContractProxyAddress!: string;
  @Prop({ type: Number, required: true }) refreshKey!: number;

  private stakingContractLogicAddress = getConfig()
    .liquidityStakingLogicAddress;

  private shortTermRewards: string | null = null;
  private longTermRewards: string | null = null;
  private APR = Big(0);

  constructor() {
    super();
    this.amountTokenDecimal = LP_DECIMALS;
  }

  private async created(): Promise<void> {
    await this.updateSmartContracts();
  }

  @Watch("pairAddress")
  private async updateSmartContracts(): Promise<void> {
    if (this.web3) {
      this.getContracts();
      await this.getSmartContractInfo();
    }
  }

  private getContracts(): void {
    this.stakingContract = new this.web3!.eth.Contract(
      LiquidityStakingAbi as AbiItem[],
      this.stakingContractProxyAddress!
    );
    this.tokenContract = new this.web3!.eth.Contract(
      UniswapV2PairAbi as AbiItem[],
      this.tokenContractProxyAddress!
    );
  }

  @Watch("refreshKey")
  @Watch("senderAccount")
  private async getSmartContractInfo(): Promise<void> {
    if (this.senderAccount) {
      await this.getAvailableBalance();
      await this.getAllowance();
      await this.getStake();
      await this.getPendingRewards();
    }
    await this.getTotalStake();
    await this.calculateAPR();
  }

  private async sendSmartContractCall(): Promise<void> {
    let amountString = "";
    if (this.amountToken && this.currentAction !== Action.CLAIM) {
      amountString = tokenToUnit(this.amountToken!, LP_DECIMALS);
    }

    if (
      this.currentAction === Action.STAKE ||
      this.currentAction === Action.UNSTAKE
    ) {
      this.startValidatingAmount = true;
      if (!this.amountIsValid) {
        return;
      }
    }

    this.awaitingContractCall = true;
    try {
      switch (this.currentAction) {
        case Action.APPROVE:
          await this.approve();
          break;
        case Action.STAKE:
          await this.stake(amountString);
          break;
        case Action.UNSTAKE:
          await this.unstake(amountString);
          break;
        case Action.CLAIM:
          await this.claim();
          break;
      }
    } catch (e) {
      console.error(e);
      this.metamaskProblem = e.message;
    } finally {
      this.awaitingContractCall = false;
      this.getSmartContractInfo();
    }
  }

  private async getPendingRewards(): Promise<void> {
    let method = this.stakingContract.methods.estimateShortReward(
      this.senderAccount
    );
    this.shortTermRewards = await method.call();

    method = this.stakingContract.methods.estimateLongReward(
      this.senderAccount
    );
    this.longTermRewards = await method.call();
  }

  private async calculateAPR(): Promise<void> {
    if (this.totalStakeUnits) {
      const totalLPSupply = await this.tokenContract.methods
        .totalSupply()
        .call();

      const whydInLP = await this.getWhydInLP();
      const fractionStaked =
        parseInt(this.totalStakeUnits) / parseInt(totalLPSupply);
      this.APR = Big(REWARD_LIQUIDITY_POOL / (fractionStaked * whydInLP)).mul(
        100
      );
    }
  }

  private async getWhydInLP(): Promise<number> {
    const whydContract = new this.web3!.eth.Contract(
      WrappedHydraAbi as AbiItem[],
      this.whydContractAddress!
    );
    const totalWhydTokens = await whydContract.methods
      .balanceOf(this.tokenContract.options.address)
      .call();
    return 2 * totalWhydTokens;
  }

  private get infoItems(): { name: string; value: unknown }[] {
    return [
      {
        name: "LP Token Balance",
        value: {
          amount: `${formatUnitBalances(
            this.maxAmountUnits,
            LP_DECIMALS
          )} LP Tokens`,
          info:
            "Your current liquidity that is fully available. You obtain LP Tokens when you supply liquidity in the Uniswap V2 pool.",
        },
      },
      {
        name: "LP Token Staked",
        value: {
          amount: `${formatUnitBalances(
            this.currentStakeUnits,
            LP_DECIMALS
          )} LP Tokens`,
          info:
            "The amount of liquidity that you have locked up in the staking smart contract.",
        },
      },
      {
        name: "Claimable Rewards",
        value: {
          amount: `${formatUnitBalances(
            this.shortTermRewards,
            WHYD_DECIMALS
          )} WHYD`,
          info: "You can withdraw half of your rewards immediately.",
        },
      },
      {
        name: "Long-term Rewards",
        value: {
          amount: `${formatUnitBalances(
            this.longTermRewards,
            WHYD_DECIMALS
          )} WHYD`,
          info: "Half of your rewards can only be accessed after 1 year.",
        },
      },
      {
        name: "APR",
        value: {
          amount: `${this.APR.toFixed(2)} %`,
          info: `The annualized percentage rate. This value depends on the total locked stake.
            There is no guarantee that this will stay the same in the future.`,
        },
      },
      {
        name: "Total LP Tokens Locked for Staking",
        value: {
          amount: `${formatUnitBalances(
            this.totalStakeUnits,
            LP_DECIMALS
          )} LP Tokens`,
          info: "The total amount of liquidity staked by all participants.",
        },
      },
    ];
  }

  private Action: typeof Action = Action;
}
