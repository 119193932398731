

























import { Component, InjectReactive, Vue } from "vue-property-decorator";
import Web3 from "web3";
import BurnStep1 from "./BurnStep1.vue";
import BurnStep2 from "./BurnStep2.vue";
import BurnStep3 from "./BurnStep3.vue";

@Component({
  components: {
    BurnStep1,
    BurnStep2,
    BurnStep3,
  },
})
export default class Burn extends Vue {
  @InjectReactive("whydContractAddress") whydContractAddress!: string | null;
  private provider: any | null = null;
  private web3: Web3 | null = null;

  private activeStep = 1;

  private step1Completed = false;
  private step2Completed = false;
  private step3Completed = false;

  private targetHydAddress: string | null = null;
  private metamaskTxId: string | null = null;

  private onStep1Complete(targetHydAddress: string): void {
    this.targetHydAddress = targetHydAddress;
    this.step1Completed = true;
    this.activeStep++;
  }

  private onStep2Complete(txId?: string): void {
    this.metamaskTxId = txId ?? null;
    this.step2Completed = true;
    this.activeStep++;
  }

  private onStep3Complete(): void {
    this.step3Completed = true;
  }
}
