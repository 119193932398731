















































































import { Component, ProvideReactive, Vue } from "vue-property-decorator";
import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
import Big from "big.js";
import { AbiItem } from "web3-utils";
import WrappedHydraAbi from "@/contracts/WrappedHydra.json";
import { AccountButton, MetaMaskConnect } from "@/components";
import { Backend } from "./api";
import { getConfig } from "./config";

interface ProviderMessage {
  type: string;
  data: unknown;
}

@Component({
  components: {
    AccountButton,
    MetaMaskConnect,
  },
})
export default class App extends Vue {
  @ProvideReactive("web3")
  private web3: Web3 | null = null;
  @ProvideReactive("provider")
  private provider: any | null = null;
  @ProvideReactive("ethereumAccount")
  private ethereumAccount: string | null = null;
  @ProvideReactive("whydContractAddress")
  private whydContractAddress: string | null = null;
  private loading = true;
  private backendError: string | null = null;
  private ethBalance = "0";
  private whydBalance = "0";

  private async created(): Promise<void> {
    this.provider = await detectEthereumProvider({ mustBeMetaMask: true });
    if (this.provider) {
      this.web3 = new Web3((window as any).web3.currentProvider);
      const accounts = await this.web3.eth.getAccounts();
      this.ethereumAccount = accounts[0];
    }

    await this.getBackendInfo();

    if (!this.backendError) {
      await this.loadBalances();
    }
  }

  private async loadBalances(): Promise<void> {
    if (!this.web3 || !this.ethereumAccount) {
      return;
    }

    const wei = await this.web3!.eth.getBalance(this.ethereumAccount!);
    this.ethBalance = parseFloat(Web3.utils.fromWei(wei)).toFixed(4);
    this.whydBalance = await this.getWhydBalance();
  }

  private updated(): void {
    if (this.provider) {
      this.provider.on("chainChanged", () => window.location.reload());
      this.provider.on("message", async (message: ProviderMessage) => {
        if (message.type === "eth_subscription") {
          await this.loadBalances();
        }
      });
      this.provider.on("accountsChanged", async (accounts: string[]) => {
        if (!accounts || accounts.length === 0) {
          // disconnected
          window.location.reload();
        }
        if (accounts[0] != this.ethereumAccount) {
          this.ethereumAccount = accounts[0];
          await this.loadBalances();
        }
      });
    }
  }

  private async getBackendInfo(): Promise<void> {
    this.loading = true;
    const appInfo = await Backend.getAppInfo();
    if (appInfo.success) {
      this.whydContractAddress = appInfo.result!.contractAddress;
      this.backendError = null;
    } else {
      this.backendError = appInfo.errorMessage!;
    }

    this.loading = false;
  }

  private async getWhydBalance(): Promise<string> {
    if (!this.ethereumAccount || !this.whydContractAddress) {
      return "0";
    }

    const contract = this.getContract();
    const contractMethod = await contract.methods.balanceOf(
      this.ethereumAccount
    );
    const amountFlakes = await contractMethod.call({
      from: this.ethereumAccount,
    });
    return Big(amountFlakes).div(1e8).toFixed(4);
  }

  private getContract(): any {
    return new this.web3!.eth.Contract(
      WrappedHydraAbi as AbiItem[],
      this.whydContractAddress!
    );
  }

  private isMenuVisible(menu: string): boolean {
    const features = getConfig().enabledFeatures.split(",");
    return features.includes(menu);
  }

  private metaMaskConnected(account: string): void {
    this.ethereumAccount = account;
  }

  private disconnect(): void {
    this.ethereumAccount = null;
  }
}
