



























































































































































import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import Web3 from "web3";
import Big from "big.js";
import { AbiItem } from "web3-utils";
import { Backend, Signature } from "@/api";
import WrappedHydraAbi from "@/contracts/WrappedHydra.json";
import { CopyButton } from "@/components/CopyButton";
import { InfoModal } from "@/components/InfoModal";
import { getConfig, getScannerUrl } from "@/config";
@Component({
  components: {
    CopyButton,
    InfoModal,
  },
})
export default class BurnStep2 extends Vue {
  @Prop({ type: Boolean, required: true }) active!: boolean;
  @Prop({ type: Boolean, required: true }) completed!: boolean;
  @Prop({ type: String, required: false }) targetHydAddress!: string;
  @Prop({ type: String, required: false }) whydContractAddress!: string;
  @InjectReactive("ethereumAccount") senderAccount!: string | null;
  @InjectReactive("web3") web3!: Web3 | null;

  private scannerUrl = getScannerUrl();

  private amountValid: boolean | null = null;
  private maxAmount = 0;
  private wHydAmount = 0;
  private wHydInvalidFeedback = "";

  private metamaskProblem: string | null = null;
  private waitingForContract = false;
  private swapTxId = "";
  private swapBeingProcessed = false;

  private loading = true;
  private backendError: string | null = null;

  // Burn params
  private hydraAddressHash: string | null = null;
  private secret: string | null = null;
  private signature: Signature | null = null;

  private get amountHex(): string {
    return this.web3!.utils.numberToHex(
      Big(this.wHydAmount).times(1e8).toFixed()
    );
  }

  private async updated(): Promise<void> {
    if (!this.targetHydAddress) {
      return;
    }

    if (this.senderAccount) {
      await this.getMaxAmount();
    }

    this.loading = false;
  }

  private async getMaxAmount(): Promise<void> {
    const contract = this.getContract();
    const contractMethod = await contract.methods.balanceOf(this.senderAccount);
    const amountFlakes = await contractMethod.call({
      from: this.senderAccount,
    });
    this.maxAmount = Big(amountFlakes).div(1e8).toNumber();
  }

  private getContract(): any {
    return new this.web3!.eth.Contract(
      WrappedHydraAbi as AbiItem[],
      this.whydContractAddress
    );
  }

  private async setBurnParameters(): Promise<void> {
    const resp = await Backend.getBurnContractCallParams(
      this.targetHydAddress,
      Big(this.wHydAmount).times(1e8).toFixed()
    );

    if (resp.success) {
      this.hydraAddressHash = resp.result!.hydAddressHash;
      this.secret = resp.result!.secret;
      this.signature = resp.result!.signature;
    } else {
      this.backendError = resp.errorMessage!;
    }
  }

  private async onCallSmartContractBtnClick(): Promise<void> {
    this.validateAmount();
    if (this.amountValid) {
      await this.setBurnParameters();
      await this.callSmartContract();
    }
  }

  private validateAmount(): void {
    if (this.wHydAmount <= 0) {
      this.wHydInvalidFeedback = "Supply a positive non-zero number!";
      this.amountValid = false;
    } else {
      this.amountValid = true;
    }
  }

  private async callSmartContract(): Promise<void> {
    this.swapBeingProcessed = true;
    this.metamaskProblem = null;

    const contractMethod = await this.createBurnMethod();

    try {
      this.waitingForContract = true;
      await this.checkChainId();
      const result = await contractMethod.send({ from: this.senderAccount });
      this.swapTxId = result.transactionHash;
      this.$emit("completed", this.swapTxId);
    } catch (e) {
      this.metamaskProblem = e.message;
    } finally {
      this.swapBeingProcessed = false;
      this.waitingForContract = false;
    }
  }

  private async createBurnMethod(): Promise<any> {
    const contract = this.getContract();
    const contractMethod = await contract.methods.burnWrappedHydra(
      this.hydraAddressHash,
      this.secret,
      Big(this.wHydAmount).times(1e8).toFixed(),
      this.signature!.v,
      this.signature!.r,
      this.signature!.s
    );
    return contractMethod;
  }

  private async checkChainId(): Promise<void> {
    const chainId = await this.web3!.eth.getChainId();
    if (chainId.toString() == getConfig().chainId) {
      return;
    }
    throw Error(
      `Your MetaMask is connected to the wrong network. Please connect to the ${
        getConfig().network
      } network.`
    );
  }

  private setMaxAmount(): void {
    this.wHydAmount = this.maxAmount;
  }

  private async onNextBtnClick(): Promise<void> {
    if (!this.hydraAddressHash) {
      await this.setBurnParameters();
      return;
    }

    this.$emit("completed");
  }
}
