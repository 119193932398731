


























































































































import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import Big from "big.js";
import WrappedHydraAbi from "@/contracts/WrappedHydra.json";
import { ContractCallParamsForMint } from "@/api";
import { InfoModal } from "@/components/InfoModal";
import { CopyButton } from "@/components/CopyButton";
import { getConfig, getScannerUrl } from "@/config";

@Component({
  components: {
    InfoModal,
    CopyButton,
  },
})
export default class MintStep3 extends Vue {
  @Prop({ type: Boolean, required: true }) active!: boolean;
  @Prop({ type: Boolean, required: true }) completed!: boolean;
  @Prop({ type: Object, required: false }) mintInfo!: ContractCallParamsForMint;
  @Prop({ type: String, required: false }) whydContractAddress!: string;
  @InjectReactive("web3") web3!: Web3;
  @InjectReactive("ethereumAccount") account!: string | null;

  private scannerUrl: string = getScannerUrl();

  private metamaskProblem: string | null = null;
  private swapBeingProcessed = false;
  private swapped = false;
  private swapTxId = "";
  private waitingForTheContract = false;

  private get swapAmount(): string {
    if (this.mintInfo) {
      return Big(BigInt(this.mintInfo.amountHex).toString()).div(1e8).toFixed();
    }

    return "?";
  }

  private get contractUrl(): string {
    if (this.mintInfo) {
      return `${this.scannerUrl}/address/${this.whydContractAddress}`;
    }

    return "?";
  }

  private async onClaimClick(): Promise<void> {
    this.swapBeingProcessed = true;
    this.metamaskProblem = null;

    const contract = new this.web3!.eth.Contract(
      WrappedHydraAbi as AbiItem[],
      this.whydContractAddress
    );

    const contractMethod = contract.methods.mintWrappedHydra(
      this.mintInfo.hydraTxHash,
      this.mintInfo.amountHex,
      this.mintInfo.signature.v,
      this.mintInfo.signature.r,
      this.mintInfo.signature.s
    );

    try {
      await this.checkChainId();
      this.waitingForTheContract = true;
      const result = await contractMethod.send({ from: this.account });
      this.swapTxId = result.transactionHash;
      this.swapped = true;
      this.$emit("completed");
    } catch (e) {
      console.log(e);
      this.metamaskProblem = e.message;
    } finally {
      this.swapBeingProcessed = false;
      this.waitingForTheContract = false;
    }
  }

  private async checkChainId(): Promise<void> {
    const chainId = await this.web3!.eth.getChainId();
    if (chainId.toString() == getConfig().chainId) {
      return;
    }
    throw Error(
      `Your MetaMask is connected to the wrong network. Please connect to the ${
        getConfig().network
      } network.`
    );
  }
}
