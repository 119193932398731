













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class InfoTable extends Vue {
  @Prop({ type: Array, required: true }) items!: {
    name: string;
    value: unknown;
  }[];
}
